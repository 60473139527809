import * as React from "react";
import { Box } from "@mui/material";

export interface ISectionContainerProps {
  children: React.ReactNode;
  vCentre?: boolean;
  vPadding?: string;
  id?: string;
}

export function SectionContainer(props: ISectionContainerProps) {
  return (
    <span>
      <Box
        sx={{
          height: !props.vPadding ? "100vh" : "",
        }}
        display="flex"
        justifyContent={props.vCentre ? "center" : ""}
        flexDirection="column"
        id={props.vPadding ? "" : props.id}
      >
        <Box
          component="div"
          id={!props.vPadding ? "" : props.id}
          sx={{
            height: props.vPadding,
          }}
        >
          &nbsp;
        </Box>
        {props.children}
      </Box>
    </span>
  );
}
