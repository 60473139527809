import { createTheme } from "@mui/material";

export const CustomTheme = () =>
  createTheme({
    typography: {
      fontFamily: ["Raleway, san-serif", "Arial"].join(","),
      body1: {
        fontSize: "1.2rem",
      },
    },
    palette: {
      mode: "dark",
      background: {
        default: "#1F2833",
        paper: "#353d47",
      },
      primary: {
        main: "#66FCF1",
      },
      secondary: {
        main: "#4D9DE0",
      },
      text: {
        primary: "#EAEAEA",
        secondary: "#AEAEAE",
      },
      action: {
        focus: "red",
      },
    },
  });
