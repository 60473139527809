import * as React from "react";
import { Typography, Box, Theme } from "@mui/material";
import { usingMobile } from "../../data/usingMobile";

export function Footer() {
  const isMobile = usingMobile();
  return (
    <Box sx={{ marginTop: isMobile ? "40px" : "80px", marginBottom: "40px" }}>
      <Typography
        variant="subtitle2"
        sx={{
          color: (theme: Theme) => theme.palette.text.disabled,
          textAlign: "center",
        }}
      >
        Website created by Jacob Morris
      </Typography>
    </Box>
  );
}
