import * as React from "react";
import { Grid, Theme, Box, useTheme } from "@mui/material";
import { HeaderCharacter } from "../common/HeaderCharacter";
import { HeaderNavbarItem } from "../common/HeaderNavbarItem";
import { NavItem } from "../../../types/NavItem";

interface IHeaderBarGridDesktopProps {
  items: NavItem[];
}

export function HeaderBarGridDesktop(props: IHeaderBarGridDesktopProps) {
  const gridStyle = {
    width: "100vw",
    padding: (theme: Theme) => theme.spacing(2),
  } as const;
  const theme = useTheme();

  return (
    <Grid container sx={gridStyle}>
      <Grid item md={4}>
        <HeaderCharacter character="J" />
      </Grid>
      <Grid item md={2} lg={4}></Grid>
      <Grid item md={6} lg={4} justifyItems="flex-end">
        <Box
          display="flex"
          justifyContent="space-around"
          sx={{
            paddingRight: (theme: Theme) => theme.spacing(4),
          }}
        >
          {props.items.map((x) => {
            return <HeaderNavbarItem text={x.text} url={x.url} key={x.url} />;
          })}
        </Box>
      </Grid>
    </Grid>
  );
}
