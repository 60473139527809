import * as React from "react";
import { Link, Box, Theme } from "@mui/material";

export interface ISocialLinksDesktopItemProps {
  children: React.ReactNode;
  href: string;
}

export function SocialLinksDesktopItem(props: ISocialLinksDesktopItemProps) {
  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      <Link
        href={props.href}
        sx={{
          color: (theme: Theme) => theme.palette.text.secondary,
        }}
        target="_blank"
      >
        {props.children}
      </Link>
    </Box>
  );
}
