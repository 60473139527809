import * as React from "react";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { CssBaseline, GlobalStyles, Container } from "@mui/material";
import { HeaderBar } from "../header/HeaderBar";
import { SocialLinks } from "../social/SocialLinks";
import { Footer } from "./Footer";
import { SEOHelmet } from "./SEOHelmet";
import { CustomTheme } from "./CustomTheme";
import { Styles } from "./Styles";

const theme = CustomTheme();

export interface ILayoutProps {
  children: React.ReactNode;
  title?: string;
  includeHeader?: boolean;
  includeSocials?: boolean;
  includeFooter?: boolean;
}

export function Layout(props: ILayoutProps) {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SEOHelmet title={props.title} />
        <CssBaseline />
        <Styles theme={theme} />
        {props.includeHeader && <HeaderBar />}
        <Container maxWidth="lg">
          {props.children}
          {props.includeSocials && <SocialLinks />}
          {props.includeFooter && <Footer />}
        </Container>
      </ThemeProvider>
    </StylesProvider>
  );
}
