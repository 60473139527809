import * as React from "react";
import { SocialData } from "../../../data/types/SocialData";
import { Box } from "@mui/material";
import { FaEnvelope, FaGithub, FaItchIo, FaLinkedin } from "react-icons/fa";
import { SocialLinksDesktopItem } from "./SocialLinksDesktopItem";

export interface ISocialLinksDesktopProps {
  data: SocialData;
}

const size = "20px";

export function SocialLinksDesktop(props: ISocialLinksDesktopProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        right: "60px",
        bottom: "40px",
      }}
    >
      <SocialLinksDesktopItem href={`mailto:${props.data.emailAddress}`}>
        <FaEnvelope size={size} />
      </SocialLinksDesktopItem>
      {props.data.linkedin ? (
        <SocialLinksDesktopItem href={props.data.linkedin}>
          <FaLinkedin size={size} />
        </SocialLinksDesktopItem>
      ) : null}
      {props.data.itch ? (
        <SocialLinksDesktopItem href={props.data.itch}>
          <FaItchIo size={size} />
        </SocialLinksDesktopItem>
      ) : null}
      {props.data.github ? (
        <SocialLinksDesktopItem href={props.data.github}>
          <FaGithub size={size} />
        </SocialLinksDesktopItem>
      ) : null}
    </Box>
  );
}
