import * as React from "react";
import { usingMobile } from "../../data/usingMobile";
import { useSocialData } from "../../data/hooks/useSocialData";
import { SocialLinksDesktop } from "./desktop/SocialLinksDesktop";
import { SocialLinksMobile } from "./mobile/SocialLinksMobile";

export function SocialLinks() {
  const isMobile = usingMobile();
  const socialData = useSocialData();
  return isMobile ? (
    <SocialLinksMobile data={socialData} />
  ) : (
    <SocialLinksDesktop data={socialData} />
  );
}
