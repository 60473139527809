import * as React from "react";
import { NavItem } from "../../../types/NavItem";
import {
  Theme,
  useTheme,
  Grid,
  IconButton,
  SwipeableDrawer,
} from "@mui/material";
import { HeaderCharacter } from "../common/HeaderCharacter";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { HeaderNavbarItem } from "../common/HeaderNavbarItem";

interface IHeaderBarGridMobileProps {
  items: NavItem[];
}

export function HeaderBarGridMobile(props: IHeaderBarGridMobileProps) {
  const gridStyle = {
    width: "100vw",
    padding: (theme: Theme) => theme.spacing(2),
  } as const;
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <Grid container sx={gridStyle}>
      <Grid item xs={4} display="flex" alignItems="center">
        <HeaderCharacter character="J" />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4} display="flex" justifyContent="flex-end">
        <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
          <MenuIcon style={{ fontSize: 40 }} />
        </IconButton>
        <SwipeableDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          anchor="right"
          sx={{
            zIndex: 10000,
          }}
          PaperProps={{
            sx: {
              backgroundColor: (theme: Theme) =>
                theme.palette.background.default,
            },
          }}
        >
          <Grid
            container
            sx={{
              width: "80vw",
              height: "60vh",
              paddingTop: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {props.items.map((x) => {
              return (
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: "center" }}
                  key={x.url + "_gridm"}
                >
                  <HeaderNavbarItem
                    text={x.text}
                    url={x.url}
                    key={x.url}
                    onClick={() => setDrawerOpen(false)}
                    mobile
                  />
                </Grid>
              );
            })}
          </Grid>
        </SwipeableDrawer>
      </Grid>
    </Grid>
  );
}
