import * as React from "react";
import { GlobalStyles, Theme } from "@mui/material";

interface IStylesProps {
  theme: Theme;
}

export function Styles(props: IStylesProps) {
  const { theme } = props;
  return (
    <GlobalStyles
      styles={{
        "*::-webkit-scrollbar": {
          width: "0.8em",
        },
        "*::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(100,100,100,.2)",
        },
        a: {
          color: theme.palette.primary.main,
          textDecoration: "none",
          transition: "0.2s",
          ":hover": {
            color: "#FFF",
          },
        },
        "a.navLink": {
          color: "#ABABAB",
          "&:hover": {
            color: theme.palette.primary.main,
            cursor: "pointer",
          },
          transition: "0.2s",
          textDecoration: "none",
          fontSize: "18px",
          fontWeight: "bold",
          marginTop: theme.spacing(1),
          textAlign: "center",
        },
        "a.activeNavLink": {
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
        blockquote: {
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          paddingLeft: theme.spacing(2),
        },
      }}
    />
  );
}
