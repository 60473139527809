import * as React from "react";
import { Helmet } from "react-helmet";
import { usePersonalInformation } from "../../data/hooks/usePersonalInformation";
import { useSeoData } from "../../data/hooks/useSEOData";

interface ISEOHelmetProps {
  title: string;
}

export function SEOHelmet(props: ISEOHelmetProps) {
  const personalInfo = usePersonalInformation();
  const seoData = useSeoData();
  const siteTitle = `${personalInfo.firstName} ${personalInfo.lastName}`;
  return (
    <Helmet
      title={props.title}
      titleTemplate={`%s - ${siteTitle}`}
      defaultTitle={siteTitle}
      meta={[
        {
          name: "description",
          content: seoData.description,
        },
        {
          name: "og:title",
          content: siteTitle,
        },
        {
          name: "og:description",
          content: seoData.description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: siteTitle,
        },
        {
          name: "twitter:description",
          content: seoData.description,
        },
      ].concat(
        seoData.keywords && seoData.keywords.length > 0
          ? {
              name: "keywords",
              content: seoData.keywords.join(","),
            }
          : []
      )}
    />
  );
}
