import * as React from "react";
import { Typography } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export interface IHeaderNavbarItemProps {
  text: string;
  url: string;
  onClick?: () => void;
  mobile?: boolean;
}

export function HeaderNavbarItem(props: IHeaderNavbarItemProps) {
  return (
    <AnchorLink
      to={props.url}
      className="navLink"
      key={props.url}
      onAnchorLinkClick={props.onClick}
    >
      <Typography
        sx={{
          fontFamily: "Rubik",
        }}
        variant={props.mobile ? "h5" : "subtitle1"}
      >
        {props.text}
      </Typography>
    </AnchorLink>
  );
}
