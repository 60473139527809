import * as React from "react";
import { SocialData } from "../../../data/types/SocialData";
import { Stack, Box, Theme } from "@mui/material";

import { FaEnvelope, FaGithub, FaItchIo, FaLinkedin } from "react-icons/fa";
import { SocialLinksMobileItem } from "./SocialLinksMobileItem";

export interface ISocialLinksMobileProps {
  data: SocialData;
}

const iconStyle = {
  fontSize: "32px",
} as const;

export function SocialLinksMobile(props: ISocialLinksMobileProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{ marginTop: (theme: Theme) => theme.spacing(16) }}
    >
      <Stack direction="row" alignItems="center" spacing={4}>
        <SocialLinksMobileItem href={props.data.emailAddress}>
          <FaEnvelope style={iconStyle} />
        </SocialLinksMobileItem>
        {props.data.linkedin && (
          <SocialLinksMobileItem href={props.data.linkedin}>
            <FaLinkedin style={iconStyle} />
          </SocialLinksMobileItem>
        )}
        {props.data.itch && (
          <SocialLinksMobileItem href={props.data.itch}>
            <FaItchIo style={iconStyle} />
          </SocialLinksMobileItem>
        )}
        {props.data.github && (
          <SocialLinksMobileItem href={props.data.github}>
            <FaGithub style={iconStyle} />
          </SocialLinksMobileItem>
        )}
      </Stack>
    </Box>
  );
}
