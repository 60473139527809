import { Theme, useTheme, Box, Typography } from "@mui/material";
import { createStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { HeaderBarGridDesktop } from "./desktop/HeaderBarGridDesktop";
import { usingMobile } from "../../data/usingMobile";
import { HeaderBarGridMobile } from "./mobile/HeaderBarGridMobile";
import { NavItem } from "../../types/NavItem";

const useStyles = createStyles((theme: Theme) => ({
  bar: {},
  mobileGrid: {
    width: "100vw",
    padding: theme.spacing(1),
    backgroundColor: `${theme.palette.background.default}CC`,
    backdropFilter: "blur(6px)",
  },
  desktopGrid: {
    width: "100vw",
    padding: theme.spacing(2),
    backgroundColor: `${theme.palette.background.default}CC`,
    backdropFilter: "blur(6px)",
  },
  logo: {},
  navItems: {
    justifyItems: "flex-end",
  },
  mobileHeader: {
    marginBottom: theme.spacing(4),
  },
  desktopHeader: {
    position: "absolute",
    top: 0,
  },
  headerAtTop: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    transition: "0.5s",
  },
  shadowBottom: {
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
    transition: "0.5s",
  },
}));

const menuItems: NavItem[] = [
  {
    text: "Home",
    url: "/#home",
  },
  {
    text: "About",
    url: "/#about",
  },
  {
    text: "Experience",
    url: "/#experience",
  },
  {
    text: "Portfolio",
    url: "/#portfolio",
  },
  /*{
    text: "Blog",
    url: "/#blog",
  },*/
  {
    text: "Contact",
    url: "/#contact",
  },
];

export function HeaderBar() {
  const theme = useTheme();
  const isMobile = usingMobile();
  const [goingUp, setGoingUp] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setAtTop(currentScrollY == 0);
      if (currentScrollY == 0) {
        setGoingUp(true);
      } else if (currentScrollY > prevScrollY.current) {
        setGoingUp(false);
      } else if (currentScrollY < prevScrollY.current) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  useEffect(() => {
    setAtTop(window.scrollY == 0);
  }, []);

  const headerBarStyle = {
    position: "fixed",
    top: 0,
    zIndex: 9999,
    marginBottom: theme.spacing(isMobile ? 8 : 4),
    paddingTop: theme.spacing(atTop ? 2 : 0),
    paddingBottom: theme.spacing(atTop ? 2 : 0),
    transition: "0.5s",
    minHeight: theme.spacing(8),
    width: "100vw",
    boxShadow: atTop ? "none" : "0 8px 16px rgba(0, 0, 0, 0.3)",
    backgroundColor: (theme: Theme) => `${theme.palette.background.default}CC`,
    backdropFilter: "blur(6px)",
  } as const;

  return (
    <Box sx={headerBarStyle}>
      {isMobile ? (
        <HeaderBarGridMobile items={menuItems} />
      ) : (
        <HeaderBarGridDesktop items={menuItems} />
      )}
    </Box>
  );
}
