import * as React from "react";
import { IconButton, Link, Theme } from "@mui/material";

export interface ISocialLinksMobileItemProps {
  children: React.ReactNode;
  href: string;
}

export function SocialLinksMobileItem(props: ISocialLinksMobileItemProps) {
  return (
    <IconButton
      aria-label="delete"
      size="large"
      component={Link}
      href={props.href}
      target="_blank"
      sx={{
        color: (theme: Theme) => theme.palette.text.secondary,
      }}
    >
      {props.children}
    </IconButton>
  );
}
