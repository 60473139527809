import { useStaticQuery, graphql } from "gatsby";
import { PersonalInformation } from "../types/PersonalInformation";

export function usePersonalInformation() {
  const data = useStaticQuery(graphql`
    query {
      sanityPersonalInformation {
        firstName
        lastName
        portrait {
          _key
          _type
          caption
          alt
          _rawAsset
          _rawHotspot
          _rawCrop
        }
        _rawAboutMe
        thirdPersonAboutMe
      }
    }
  `);

  return data.sanityPersonalInformation as PersonalInformation;
}
