import { useStaticQuery, graphql } from "gatsby";
import { SocialData } from "../types/SocialData";

export function useSocialData() {
  const data = useStaticQuery(graphql`
    query {
      sanitySocial {
        emailAddress
        linkedin
        itch
        github
      }
    }
  `);

  return data.sanitySocial as SocialData;
}
