import * as React from "react";
import { Typography, Theme } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export interface IHeaderCharacterProps {
  character: string;
}

export function HeaderCharacter(props: IHeaderCharacterProps) {
  return (
    <Typography
      variant="h4"
      sx={{
        color: (theme: Theme) => theme.palette.primary.main,
        fontWeight: 400,
        marginLeft: (theme) => theme.spacing(2),
      }}
    >
      <AnchorLink to="/#home">{props.character}</AnchorLink>
    </Typography>
  );
}
