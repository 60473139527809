import { useStaticQuery, graphql } from "gatsby";
import { SEOData } from "../types/SEOData";

export function useSeoData() {
  const data = useStaticQuery(graphql`
    query {
      sanitySeo {
        description
        keywords
      }
    }
  `);

  return data.sanitySeo as SEOData;
}
